import supabase from '~supabaseConfig';

function formatDate(inputDate) {
  var dateParts = inputDate.split("-");
  var formattedDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
  return formattedDate;
}

const fetchTreatmentData = async (arr) => {
  // Filter out entries with empty items before processing
  const filteredArr = arr.filter(item => item.item !== '');

  const uniqueItems = [...new Set(filteredArr.map(treatment => treatment.item))];

  const treatmentPromises = uniqueItems.map(async (item) => {
    try {
      const { data, error } = await supabase
        .from('rvm_items')
        .select()
        .eq('item', item);

      if (data && data.length > 0) {
        const totalQty = filteredArr
          .filter(treatment => treatment.item === item)
          .reduce((sum, treatment) => sum + (treatment.qty || 0), 0);

        return {
          product: data[0].item,
          directions: data[0].instructions,
          qty: totalQty * 4,
          meat: data[0].whp_meat,
          milk: data[0].whp_milk
        };
      } else if (error) {
        console.log('Supabase error:', error);
        return null;
      }
    } catch (error) {
      console.log('Treatment data error:', error);
      return null;
    }
  });

  const treatmentData = await Promise.all(treatmentPromises);
  return treatmentData.filter((treatment) => treatment !== null);
};

const excelValues = async (combinedData) => {
  console.log('Received combinedData:', combinedData);

  // Destructure and provide default values
  const { consultData } = combinedData || {};

  if (!consultData) {
    console.error('No consultData found in combinedData');
    throw new Error('Required data is missing');
  }

  console.log('Processing consultData:', consultData);

  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  let date;
  if (consultData?.dry_cow_id?.authorisation_date) {
    date = formatDate(consultData.dry_cow_id.authorisation_date);
  }

  // Create basic data structure with safe property access
  let milkQualityReviewData = {
    G3: formattedDate || '',
    B50: consultData?.farms?.farmer_name || '',
    B51: consultData?.farms?.farm_name || '',
    B52: consultData?.farms?.address || '',
    E51: consultData?.farms?.clinic_id ?
      `${consultData.farms.clinic_id.clinic_name || ''}, ${consultData.farms.clinic_id.address || ''}` : '',
    A3: consultData?.farms?.farm_name || '',
    E50: consultData?.farms?.vet?.name || '',
  };

  // Safely add dry cow data if it exists
  if (consultData?.dry_cow_id) {
    const dryCowData = consultData.dry_cow_id;

    // Add dry off treatment data
    Object.assign(milkQualityReviewData, {
      B8: dryCowData.uninfected_number_cows ?? '',
      B9: dryCowData.infected_number_cows ?? '',
      B10: dryCowData.infected_high_risk_number_cows ?? '',
      B11: dryCowData.chronic_number_cows ?? '',
      B12: dryCowData.heifer_number_cows ?? '',

      C8: dryCowData.uninfected_antibiotic ?? '',
      C9: dryCowData.infected_antibiotic ?? '',
      C10: dryCowData.infected_high_risk_antibiotic ?? '',
      C11: dryCowData.chronic_antibiotic ?? '',
      C12: dryCowData.heifer_antibiotic ?? '',

      D8: dryCowData.uninfected_teatsealant ?? '',
      D9: dryCowData.infected_teatsealant ?? '',
      D10: dryCowData.infected_high_risk_teatsealant ?? '',
      D11: dryCowData.chronic_teatsealant ?? '',
      D12: dryCowData.heifer_teatsealant ?? '',

      E8: dryCowData.uninfected_threshold ?? '',
      E9: dryCowData.infected_threshold ?? '',
      E10: dryCowData.infected_high_risk_threshold ?? '',
      E11: dryCowData.chronic_threshold ?? '',
      E12: dryCowData.heifer_threshold ?? '',
    });

    // Add treatment plan related data
    if (dryCowData.treatment_plan) {
      if (dryCowData.treatment_plan.includes("Blanket")) {
        Object.assign(milkQualityReviewData, {
          A22: 'I have recommended blanket dry cow for the following reasons: ',
          A27: 'In line with industry and veterinary guidelines regarding use of antibiotics use in dairy cattle, the following must be completed to authorize blanket dry cow therapy:',
          A28: '- Recording clinical cases of mastitis in an easily reviewable format.',
          A29: '- An early season milk quality review to determine the effectiveness of dry cow therapy and to identify mastitis risks in the herd. This is booked for',
        });

        // Add blanket reasons if they exist
        const blanketReasons = dryCowData.blanket_dry_cow_reasons || [];
        blanketReasons.forEach((reason, index) => {
          milkQualityReviewData[`A${23 + index}`] = '- ' + reason;
        });
      }

      if (dryCowData.treatment_plan.includes("with teatsealant")) {
        Object.assign(milkQualityReviewData, {
          A44: 'Disclaimer',
          A45: 'I intend to purchase Teatseal® for use on my herd this season ending May 2024.  I have had explained to me and fully understand the benefits and risks associated with using internal teat sealants.   ',
          A46: 'In particular, I have reviewed the handout Teat Sealant Use in Dairy Cows, product label instructions and the training video provided by Franklin Vets and understand the importance of hygiene during administration, correct placement of internal teat sealant and what is required to ensure effective removal of internal teat sealant residues at calving.  ',
          A47: 'Where required I undertake to pass this knowledge and understanding onto any persons administering internal teat sealants on my herd and ensure they comply with the requirements for administration and management.   ',
          A48: 'I acknowledge and understand that even with staff training and the strict hygiene measures used, there remains a risk of mastitis and/or death for my cattle resulting from the administration of DCAT or internal teatsealants. In the event of such events occurring, and my rights under law notwithstanding, I understand I may be liable for costs associated with emergency treatment should such events occur. I agree to follow all the advice for monitoring cattle treated with DCAT or ITS and will report any adverse outcomes following administration and undertake appropriate steps for removal of the products after calving.',
        });
      }
    }

    // Output recommended follow-up services starting at A70 and their descriptions in B70, B71, etc.
    const recommendedFollowUp = dryCowData.recommended_follow_up || [];
    const serviceDescriptions = {
      "Milking time visit post calving": "Identify high risk practices, assess machine performance and udder condition to reduce early season mastitis.",
      "Early season mastitis review": "Review of dry cow performance, early season mastitis rates and identification of high risk cows following first herd test.",
      "On farm training": "Provide farm team clear instructions on ways to keep udders healthy by preventing new cases of mastitis, finding new cases, and treating new and existing cases in the right way.",
      "Milking efficiency visit": "Milking visit to identify opportunities to improve milking efficiency"
    };

    recommendedFollowUp.forEach((service, index) => {
      milkQualityReviewData[`A${68 + index}`] = service;

      // Add the corresponding description to the neighboring cell (B70, B71, etc.)
      if (serviceDescriptions.hasOwnProperty(service)) {
        milkQualityReviewData[`C${68 + index}`] = serviceDescriptions[service];
      }
    });

    // Output recommended testing starting at A76 and their descriptions in B76, B77, etc.
    const recommendedTesting = dryCowData.recommended_testing || [];
    const testingDescriptions = {
      "Herd testing": "Book a herd test prior to dry off next year.",
      "Milk culture mastitis cases": "Submit a minimum of 10 mastitis cases from the early season. Know where the mastitis is coming from, how to manage it and identify the best treatments to give the best cures.",
      "Milk culture sub-clinical cases": "Submit 10-20 samples from cows identified following herd test and RMT testing. Know what I causing somatic cell count elevations and how to stop them.",
      "Bioscreen": "FVS technician RMTs selection of cows and collect milk samples for culture. Identifies the mastitis bugs seen on farm, determines the most appropriate treatments, helps control further infections and meets the antimicrobial monitoring requirements for various processor assurance schemes.",
      "Antibiogram": "Monitor the antimicrobial resistance profile on farm and meet antimicrobial monitoring requirements for various processor assurance schemes.",
      "On farm testing with Mastaplex": "Determine the causes of mastitis, how to treat the cow properly in real time, and understand how to improve mastitis control."
    };

    recommendedTesting.forEach((test, index) => {
      milkQualityReviewData[`A${76 + index}`] = test;

      // Add the corresponding description to the neighboring cell (B76, B77, etc.)
      if (testingDescriptions.hasOwnProperty(test)) {
        milkQualityReviewData[`C${76 + index}`] = testingDescriptions[test];
      }
    });


    // Create treatment array with safe access
    const arr = [
      {
        item: dryCowData.uninfected_antibiotic_treatment || '',
        qty: dryCowData.uninfected_number_cows || 0
      },
      {
        item: dryCowData.chronic_antibiotic || '',
        qty: dryCowData.chronic_number_cows || 0
      },
      {
        item: dryCowData.heifer_antibiotic || '',
        qty: dryCowData.heifer_number_cows || 0
      },
      {
        item: dryCowData.infected_antibiotic || '',
        qty: dryCowData.infected_number_cows || 0
      },
      {
        item: dryCowData.infected_high_risk_antibiotic || '',
        qty: dryCowData.infected_high_risk_number_cows || 0
      },
      {
        item: dryCowData.uninfected_antibiotic || '',
        qty: dryCowData.uninfected_number_cows || 0
      },
      {
        item: dryCowData.chronic_teatsealant || '',
        qty: dryCowData.chronic_number_cows || 0
      },
      {
        item: dryCowData.heifer_teatsealant || '',
        qty: dryCowData.heifer_number_cows || 0
      },
      {
        item: dryCowData.infected_high_risk_teatsealant || '',
        qty: dryCowData.infected_high_risk_number_cows || 0
      },
      {
        item: dryCowData.infected_teatsealant || '',
        qty: dryCowData.infected_number_cows || 0
      },
      {
        item: dryCowData.uninfected_teatsealant || '',
        qty: dryCowData.uninfected_number_cows || 0
      }
    ];

    // Process treatment data
    const treatmentArr = await fetchTreatmentData(arr);
    console.log('Treatment array:', treatmentArr);

    treatmentArr.forEach((treatment, index) => {
      if (treatment) {
        milkQualityReviewData[`A${18 + index}`] = treatment.product || '';
        milkQualityReviewData[`B${18 + index}`] = treatment.qty || '';
        milkQualityReviewData[`C${18 + index}`] = treatment.directions || '';
        milkQualityReviewData[`F${18 + index}`] = treatment.milk || '';
        milkQualityReviewData[`H${18 + index}`] = treatment.meat || '';
        milkQualityReviewData[`J${18 + index}`] = date || '';
      }
    });
  }

  console.log('Final milkQualityReviewData:', milkQualityReviewData);
  return { Milk_Quality_Review: milkQualityReviewData };
};

export default excelValues;