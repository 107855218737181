import React from 'react';
import { Table, Tag, Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

// Update the handleFormFinish in HealthPlannerForm:
const handleFormFinish = (values) => {
    // Get product details with their multipliers
    const productsWithMultipliers = selectedProducts.map(product => ({
        code: product.code,
        product_name: product.product_name,
        base_price: Number(product.price),
        multiplier: values.product_multipliers?.[product.code] || 1,
        final_price: Number(product.price) * (values.product_multipliers?.[product.code] || 1)
    }));

    // Calculate base price (including multiplied products)
    const basePrice = useProductPrice ? 
        productsWithMultipliers.reduce((sum, p) => sum + p.final_price, 0) : 
        values.price || 0;

    // Apply global multiplication factor if any
    const multiplyBy = values.multiply_by;
    const hasMultiplication = Array.isArray(multiplyBy) ? multiplyBy.length > 0 : !!multiplyBy;
    const multiplyCost = values.multiply_cost || 1;
    
    // Calculate the final price including multiplication and additional costs
    const multipliedBasePrice = hasMultiplication ? basePrice * multiplyCost : basePrice;
    const additionalCostsTotal = selectedCosts.reduce((sum, cost) => sum + Number(cost.price), 0);
    const finalPrice = multipliedBasePrice + additionalCostsTotal;

    onFinish({
        ...values,
        name: formatName(values.title),
        price: finalPrice,
        products: productsWithMultipliers,  // Include detailed product info
        base_price: basePrice,              // Price before global multiplication
        multiplied_price: multipliedBasePrice, // Price after global multiplication
        final_price: finalPrice,            // Total price including everything
        additional_costs: selectedCosts.map(cost => ({
            id: cost.id,
            name: cost.name,
            price: Number(cost.price)
        })),
        multiply_factor: hasMultiplication ? multiplyCost : null
    });
};

// Updated HealthPlannerTable component:
const HealthPlannerTable = ({ items, onEdit, onDelete }) => {
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            filters: [
                { text: 'Preventive', value: 'Preventive' },
                { text: 'Treatment', value: 'Treatment' },
                { text: 'Testing', value: 'Testing' },
                { text: 'Vaccination', value: 'Vaccination' },
                { text: 'RVM - Milk Quality Data', value: 'RVM - Milk Quality Data' },
            ],
            onFilter: (value, record) => record.category === value,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price, record) => {
                const hasProducts = record.products?.length > 0;
                const hasAdditionalCosts = record.additional_costs?.length > 0;
                const hasMultiplication = record.multiply_factor && record.multiply_factor !== 1;

                if (!hasProducts && !hasAdditionalCosts && !hasMultiplication) {
                    return `$${Number(price).toFixed(2)}`;
                }

                return (
                    <div className="flex items-center gap-1">
                        <span>${Number(price).toFixed(2)}</span>
                        <Tooltip
                            title={
                                <div className="space-y-1">
                                    <Text strong className="text-white">Price Breakdown:</Text>
                                    
                                    {/* Products with multipliers */}
                                    {hasProducts && (
                                        <>
                                            <Text className="text-white">Products:</Text>
                                            {record.products.map(product => (
                                                <div key={product.code} className="flex justify-between gap-4">
                                                    <span>
                                                        {product.product_name}
                                                        {product.multiplier > 1 && (
                                                            <Text type="secondary">
                                                                {` × ${product.multiplier}`}
                                                            </Text>
                                                        )}
                                                    </span>
                                                    <span>
                                                        ${Number(product.final_price).toFixed(2)}
                                                    </span>
                                                </div>
                                            ))}
                                            <div className="pt-1 border-t border-white/20">
                                                <Text className="text-white">Products Total: ${Number(record.base_price).toFixed(2)}</Text>
                                            </div>
                                        </>
                                    )}

                                    {/* Global multiplication */}
                                    {hasMultiplication && (
                                        <div className="pt-1 border-t border-white/20">
                                            <Text className="text-white">
                                                After Multiplication (×{record.multiply_factor}): ${Number(record.multiplied_price).toFixed(2)}
                                            </Text>
                                        </div>
                                    )}

                                    {/* Additional costs */}
                                    {hasAdditionalCosts && (
                                        <>
                                            <div className="pt-1 border-t border-white/20">
                                                <Text className="text-white">Additional Costs:</Text>
                                                {record.additional_costs.map(cost => (
                                                    <div key={cost.id} className="flex justify-between gap-4">
                                                        <span>{cost.name}</span>
                                                        <span>${Number(cost.price).toFixed(2)}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}

                                    {/* Final total */}
                                    <div className="pt-1 mt-1 border-t border-white/20">
                                        <Text strong className="text-white">
                                            Final Total: ${Number(price).toFixed(2)}
                                        </Text>
                                    </div>
                                </div>
                            }
                            color="#1f1f1f"
                        >
                            <InfoCircleOutlined className="text-blue-500 cursor-help" />
                        </Tooltip>
                    </div>
                );
            },
            sorter: (a, b) => a.price - b.price,
        },
        {
            title: 'Status',
            key: 'status',
            render: (_, record) => (
                <Space size="small">
                    {record.vaccination && <Tag color="blue">Vaccination</Tag>}
                    {record.testing && <Tag color="green">Testing</Tag>}
                    {record.calves_reared && <Tag color="orange">Calves</Tag>}
                    {record.is_multiple_service && <Tag color="purple">Multiple</Tag>}
                </Space>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <button
                        onClick={() => onEdit(record)}
                        className="text-blue-600 hover:text-blue-800"
                    >
                        Edit
                    </button>
                    <button
                        onClick={() => onDelete(record.id)}
                        className="text-red-600 hover:text-red-800"
                    >
                        Delete
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={items}
            rowKey="id"
            pagination={{ pageSize: 10 }}
        />
    );
};

export default HealthPlannerTable;