import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Switch, Select, Space, Typography, Card } from 'antd';
import supabase from '~supabaseConfig';

const { Text } = Typography;

const MULTIPLY_BY_OPTIONS = [
    { value: 'herd_size', label: 'Herd Size' },
    { value: 'number_heifers', label: 'Number Heifers' },
    { value: 'calves_reared', label: 'Calves Reared' }
];

const formatName = (title) => {
    return title
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '')
        .replace(/\s+/g, '_');
};

const HealthPlannerForm = ({ form, onFinish, editingId }) => {
    const [products, setProducts] = useState([]);
    const [healthCosts, setHealthCosts] = useState([]);
    const [useProductPrice, setUseProductPrice] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCosts, setSelectedCosts] = useState([]);

    useEffect(() => {
        fetchProducts();
        fetchHealthCosts();
    }, []);

    useEffect(() => {
        if (editingId) {
            const formData = form.getFieldsValue();
            if (formData.product_refs?.length > 0) {
                setUseProductPrice(true);
                handleProductSelect(formData.product_refs);
            }
            if (formData.add_to_cost?.length > 0) {
                handleCostSelect(formData.add_to_cost);
            }
        }
    }, [editingId, form]);

    // ... [fetchHealthCosts and fetchProducts functions remain the same]
    const fetchProducts = async () => {
        const { data, error } = await supabase
            .from('animal_health_stock')
            .select('*')
            .order('product_name');

        if (error) console.error('Error fetching products:', error);
        else setProducts(data);
    };

    const fetchHealthCosts = async () => {
        const { data, error } = await supabase
            .from('animal_health_costs')
            .select('*')
            .order('name');

        if (error) console.error('Error fetching health costs:', error);
        else setHealthCosts(data);
    };



    const handleProductSelect = (value) => {
        const selectedProds = products.filter(p => value.includes(p.code));
        setSelectedProducts(selectedProds);

        const existingMultipliers = form.getFieldValue('product_multipliers') || {};
        const newMultipliers = {};
        selectedProds.forEach(product => {
            if (!existingMultipliers[product.code]) {
                newMultipliers[product.code] = 1;
            }
        });

        form.setFieldsValue({
            product_refs: value,
            product_multipliers: { ...existingMultipliers, ...newMultipliers }
        });
    };

    const handleCostSelect = (value) => {
        const selectedCostItems = healthCosts.filter(cost => value.includes(cost.id.toString()));
        setSelectedCosts(selectedCostItems);

        const existingCostMultipliers = form.getFieldValue('cost_multipliers') || {};
        const newCostMultipliers = {};
        selectedCostItems.forEach(cost => {
            if (!existingCostMultipliers[cost.id]) {
                newCostMultipliers[cost.id] = 1;
            }
        });

        form.setFieldsValue({
            add_to_cost: value,
            cost_multipliers: { ...existingCostMultipliers, ...newCostMultipliers }
        });
    };

    const calculateProductsTotal = () => {
        const multipliers = form.getFieldValue('product_multipliers') || {};
        return selectedProducts.reduce((sum, p) => {
            const multiplier = multipliers[p.code] || 1;
            return sum + (Number(p.price) * multiplier);
        }, 0);
    };

    const calculateCostsTotal = () => {
        const costMultipliers = form.getFieldValue('cost_multipliers') || {};
        return selectedCosts.reduce((sum, cost) => {
            const multiplier = costMultipliers[cost.id] || 1;
            return sum + (Number(cost.price) * multiplier);
        }, 0);
    };

    const calculateTotalPrice = () => {
        // Calculate base total from products or manual entry
        let baseTotal = useProductPrice ? calculateProductsTotal() : (form.getFieldValue('price') || 0);

        // Apply multiplication factors to base price only
        const multiplyBy = form.getFieldValue('multiply_by');
        const hasMultiplication = Array.isArray(multiplyBy) ? multiplyBy.length > 0 : !!multiplyBy;
        const multiplyCost = form.getFieldValue('multiply_cost') || 1;

        // Apply multiplication if selected
        const multipliedTotal = hasMultiplication ? baseTotal * multiplyCost : baseTotal;

        // Add costs with their individual multipliers (not affected by global multiplication)
        return multipliedTotal + calculateCostsTotal();
    };

    const handleFormFinish = (values) => {
        const finalPrice = calculateTotalPrice();

        onFinish({
            ...values,
            name: formatName(values.title),
            price: finalPrice,
            product_refs: useProductPrice ? values.product_refs : [],
            product_multipliers: useProductPrice ? values.product_multipliers : {},
            cost_multipliers: values.cost_multipliers || {},
            final_price: finalPrice,
            additional_costs: selectedCosts.map(cost => ({
                id: cost.id,
                name: cost.name,
                price: cost.price,
                multiplier: values.cost_multipliers?.[cost.id] || 1
            }))
        });
    };

    return (
        <Form
            form={form}
            onFinish={handleFormFinish}
            layout="vertical"
            initialValues={{
                calves_reared: false,
                vaccination: false,
                testing: false,
                is_multiple_service: false,
                add_to_cost: [],
                multiply_by: [],
                multiply_cost: 1,
                product_refs: [],
                product_multipliers: {},
                cost_multipliers: {}
            }}
        >
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Card className="mb-4">
                <Space align="center" style={{ marginBottom: 16 }}>
                    <Switch
                        checked={useProductPrice}
                        onChange={checked => {
                            setUseProductPrice(checked);
                            if (!checked) {
                                form.setFieldsValue({
                                    product_refs: [],
                                    product_multipliers: {}
                                });
                                setSelectedProducts([]);
                            }
                        }}
                    />
                    <span>{useProductPrice ? 'Calculate from products' : 'Enter manually'}</span>
                </Space>

                {useProductPrice ? (
                    <>
                        <Form.Item name="product_refs" label="Select Products">
                            <Select
                                mode="multiple"
                                placeholder="Select products"
                                style={{ width: '100%' }}
                                onChange={handleProductSelect}
                                optionFilterProp="label"
                            >
                                {products.map(product => (
                                    <Select.Option
                                        key={product.code}
                                        value={product.code}
                                        label={`${product.product_name} ($${product.price})`}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>{product.product_name}</span>
                                            <span style={{ color: '#999' }}>${product.price}</span>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item name="product_multipliers" hidden>
                            <Input />
                        </Form.Item>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                            {selectedProducts.map(product => (
                                <Card key={product.code} size="small" style={{ marginBottom: 8 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <div>{product.product_name}</div>
                                            <div style={{ color: '#999' }}>${product.price}</div>
                                        </div>
                                        <Space>
                                            <span>×</span>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return prevValues.product_multipliers?.[product.code] !==
                                                        currentValues.product_multipliers?.[product.code];
                                                }}
                                            >
                                                {({ getFieldValue, setFieldsValue }) => (
                                                    <InputNumber
                                                        min={0}
                                                        step={0.1}
                                                        value={getFieldValue(['product_multipliers', product.code]) || 1}
                                                        onChange={(value) => {
                                                            const multipliers = getFieldValue('product_multipliers') || {};
                                                            setFieldsValue({
                                                                product_multipliers: {
                                                                    ...multipliers,
                                                                    [product.code]: value
                                                                }
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) => {
                                                    return prevValues.product_multipliers?.[product.code] !==
                                                        currentValues.product_multipliers?.[product.code];
                                                }}
                                            >
                                                {({ getFieldValue }) => {
                                                    const multiplier = getFieldValue(['product_multipliers', product.code]) || 1;
                                                    return (
                                                        <Text strong style={{ minWidth: 80, textAlign: 'right' }}>
                                                            ${(Number(product.price) * multiplier).toFixed(2)}
                                                        </Text>
                                                    );
                                                }}
                                            </Form.Item>
                                        </Space>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </>
                ) : (
                    <Form.Item
                        name="price"
                        label="Price"
                        rules={[{ required: true, message: 'Please enter a price' }]}
                    >
                        <InputNumber
                            min={0}
                            step={0.01}
                            style={{ width: '100%' }}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                )}
            </Card>

            <Card title="Multiplication Factors" className="mb-4">
                <Form.Item
                    name="multiply_by"
                    label="Multiply Base Price By"
                    tooltip="Select multiplication factors for the base price"
                >
                    <Select
                        mode="multiple"
                        placeholder="Select multiplication factors"
                        options={MULTIPLY_BY_OPTIONS}
                        optionFilterProp="label"
                    />
                </Form.Item>

                <Form.Item
                    name="multiply_cost"
                    label="Multiplication Factor"
                    tooltip="Enter the multiplication factor for the base price"
                >
                    <InputNumber
                        min={0}
                        step={0.1}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Card>

            <Card title="Additional Costs" className="mb-4">
                <Form.Item
                    name="add_to_cost"
                    tooltip="Select additional costs to add after multiplication"
                >
                    <Select
                        mode="multiple"
                        placeholder="Select additional costs"
                        onChange={handleCostSelect}
                        optionFilterProp="label"
                        style={{ width: '100%' }}
                    >
                        {healthCosts.map(cost => (
                            <Select.Option
                                key={cost.id}
                                value={cost.id.toString()}
                                label={`${cost.name} ($${Number(cost.price).toFixed(2)})`}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>{cost.name}</span>
                                    <span style={{ color: '#999' }}>${Number(cost.price).toFixed(2)}</span>
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="cost_multipliers" hidden>
                    <Input />
                </Form.Item>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {selectedCosts.map(cost => (
                        <Card key={cost.id} size="small" style={{ marginBottom: 8 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <div>{cost.name}</div>
                                    <div style={{ color: '#999' }}>${Number(cost.price).toFixed(2)}</div>
                                </div>
                                <Space>
                                    <span>×</span>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues.cost_multipliers?.[cost.id] !==
                                                currentValues.cost_multipliers?.[cost.id];
                                        }}
                                    >
                                        {({ getFieldValue, setFieldsValue }) => (
                                            <InputNumber
                                                min={0}
                                                step={0.1}
                                                value={getFieldValue(['cost_multipliers', cost.id]) || 1}
                                                onChange={(value) => {
                                                    const multipliers = getFieldValue('cost_multipliers') || {};
                                                    setFieldsValue({
                                                        cost_multipliers: {
                                                            ...multipliers,
                                                            [cost.id]: value
                                                        }
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues.cost_multipliers?.[cost.id] !==
                                                currentValues.cost_multipliers?.[cost.id];
                                        }}
                                    >
                                        {({ getFieldValue }) => {
                                            const multiplier = getFieldValue(['cost_multipliers', cost.id]) || 1;
                                            return (
                                                <Text strong style={{ minWidth: 80, textAlign: 'right' }}>
                                                    ${(Number(cost.price) * multiplier).toFixed(2)}
                                                </Text>
                                            );
                                        }}
                                    </Form.Item>
                                </Space>
                            </div>
                        </Card>
                    ))}
                </div>
            </Card>

            {/* Price Breakdown Section */}
            {(selectedProducts.length > 0 || selectedCosts.length > 0 || (!useProductPrice && form.getFieldValue('price'))) && (
                <Card title="Price Breakdown" className="mb-4">
                    {useProductPrice ? (
                        <>
                            <Text strong>Products:</Text>
                            {selectedProducts.map(product => {
                                const multipliers = form.getFieldValue('product_multipliers') || {};
                                const multiplier = multipliers[product.code] || 1;
                                return (
                                    <div key={product.code} style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 16, marginBottom: 4 }}>
                                        <span>
                                            {product.product_name}
                                            {multiplier !== 1 && (
                                                <Text type="secondary" style={{ marginLeft: 8 }}>
                                                    (${Number(product.price).toFixed(2)} × {multiplier})
                                                </Text>
                                            )}
                                        </span>
                                        <Text>${(Number(product.price) * multiplier).toFixed(2)}</Text>
                                    </div>
                                );
                            })}
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #f0f0f0', paddingTop: 8, marginTop: 8 }}>
                                <Text>Base Price (Products Total):</Text>
                                <Text strong>${calculateProductsTotal().toFixed(2)}</Text>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                            <Text>Base Price:</Text>
                            <Text strong>${(form.getFieldValue('price') || 0).toFixed(2)}</Text>
                        </div>
                    )} {Array.isArray(form.getFieldValue('multiply_by')) && form.getFieldValue('multiply_by').length > 0 && (
                        <div style={{ borderTop: '1px solid #f0f0f0', paddingTop: 8, marginTop: 8 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <Text>After Multiplication ({form.getFieldValue('multiply_cost')}×)</Text>
                                    <Text type="secondary" style={{ marginLeft: 8 }}>
                                        ({(form.getFieldValue('multiply_by') || []).map(item => {
                                            const option = MULTIPLY_BY_OPTIONS.find(opt => opt.value === item);
                                            return option?.label;
                                        }).join(', ')})
                                    </Text>
                                </div>
                                <Text strong>
                                    ${((useProductPrice ? calculateProductsTotal() : (form.getFieldValue('price') || 0))
                                        * (form.getFieldValue('multiply_cost') || 1)).toFixed(2)}
                                </Text>
                            </div>
                        </div>
                    )}
                    {selectedCosts.length > 0 && (
                        <div style={{ borderTop: '1px solid #f0f0f0', paddingTop: 8, marginTop: 8 }}>
                            <Text strong>Additional Costs:</Text>
                            {selectedCosts.map(cost => {
                                const costMultipliers = form.getFieldValue('cost_multipliers') || {};
                                const multiplier = costMultipliers[cost.id] || 1;
                                return (
                                    <div key={cost.id} style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 16, marginBottom: 4 }}>
                                        <span>
                                            {cost.name}
                                            {multiplier !== 1 && (
                                                <Text type="secondary" style={{ marginLeft: 8 }}>
                                                    (${Number(cost.price).toFixed(2)} × {multiplier})
                                                </Text>
                                            )}
                                        </span>
                                        <Text>${(Number(cost.price) * multiplier).toFixed(2)}</Text>
                                    </div>
                                );
                            })}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                                <Text>Additional Costs Total:</Text>
                                <Text strong>${calculateCostsTotal().toFixed(2)}</Text>
                            </div>
                        </div>
                    )}

                    <div style={{ borderTop: '1px solid #f0f0f0', paddingTop: 8, marginTop: 8 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Text strong style={{ fontSize: 16 }}>Final Total:</Text>
                            <Text strong style={{ fontSize: 16 }}>
                                ${calculateTotalPrice().toFixed(2)}
                            </Text>
                        </div>
                    </div>
                </Card>
            )}

            <Card className="mb-4">
                <Form.Item
                    name="category"
                    label="Category"
                    rules={[{ required: true }]}
                >
                    <Select>
                        <Select.Option value="Preventive">Preventive</Select.Option>
                        <Select.Option value="Treatment">Treatment</Select.Option>
                        <Select.Option value="Testing">Testing</Select.Option>
                        <Select.Option value="Vaccination">Vaccination</Select.Option>
                        <Select.Option value="RVM - Milk Quality Data">RVM - Milk Quality Data</Select.Option>
                    </Select>
                </Form.Item>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
                    <Form.Item
                        name="vaccination"
                        label="Vaccination"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        name="testing"
                        label="Testing"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        name="calves_reared"
                        label="Calves Reared"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        name="is_multiple_service"
                        label="Multiple Service"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </div>
            </Card>
        </Form>
    );
};

export default HealthPlannerForm;