import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Input, Typography, Checkbox, Select, Form, Button, InputNumber, message } from 'antd';
import { Box, Table } from '@mui/joy'
import { useParams } from 'react-router-dom';
import supabase from '~supabaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';

const FormItem = Form.Item;
const { Text } = Typography;

export default function LSMQR() {
  const [form] = Form.useForm();
  const { farmId, milkQualityConsultId } = useParams();
  const [formInitialized, setFormInitialized] = useState(false);
  const [herdSize, setHerdSize] = useState(0);
  const [heiferSize, setHeiferSize] = useState(0);
  const [cowMastitisEarly, setCowMastitisEarly] = useState(0);
  const [cowMastitisMid, setCowMastitisMid] = useState(0);
  const [cowMastitisLate, setCowMastitisLate] = useState(0);
  const [cowMastitisDry, setCowMastitisDry] = useState(0);
  const [cowMastitisTotal, setCowMastitisTotal] = useState(0);
  const [heiferMastitisEarly, setHeiferMastitisEarly] = useState(0);
  const [heiferMastitisMid, setHeiferMastitisMid] = useState(0);
  const [heiferMastitisLate, setHeiferMastitisLate] = useState(0);
  const [heiferMastitisDry, setHeiferMastitisDry] = useState(0);
  const [heiferMastitisTotal, setHeiferMastitisTotal] = useState(0);
  const [saving, setSaving] = useState(false);

  const debouncedSaveRef = useRef();

  // Debounced save function
  const saveData = async (values) => {
    try {
      setSaving(true);
      const { data: existingData, error: fetchError } = await supabase
        .from('milk_quality_consult_lsmqr_data')
        .select('id')
        .eq('milk_quality_consult_id', milkQualityConsultId)
        .limit(1);

      if (fetchError) {
        throw new Error(`Error checking existing LSMQR: ${fetchError.message}`);
      }

      if (existingData && existingData.length > 0) {
        const { error: updateError } = await supabase
          .from('milk_quality_consult_lsmqr_data')
          .update(values)
          .eq('id', existingData[0].id);

        if (updateError) {
          throw new Error(`Error updating LSMQR: ${updateError.message}`);
        }
        message.success('Autosaved successfully');
      } else {
        const LSMQRId = uuidv4();
        const { error: insertError } = await supabase
          .from('milk_quality_consult_lsmqr_data')
          .insert([{ ...values, milk_quality_consult_id: milkQualityConsultId, id: LSMQRId }]);

        if (insertError) {
          throw new Error(`Error saving LSMQR: ${insertError.message}`);
        }

        const { error: updateRvmError } = await supabase
          .from('milk_quality_consults')
          .update({ lsmqr_id: LSMQRId })
          .eq('id', milkQualityConsultId);

        if (updateRvmError) {
          throw new Error(`Error updating rvm with LSMQR id: ${updateRvmError.message}`);
        }
        message.success('Autosaved successfully');
      }
    } catch (error) {
      console.error('Error in autosave:', error);
      message.error('Error autosaving. Changes may not be saved.');
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    debouncedSaveRef.current = debounce(saveData, 2000);

    return () => {
      if (debouncedSaveRef.current) {
        debouncedSaveRef.current.cancel();
      }
    };
  }, [milkQualityConsultId]);

  // Form change handler
  const handleFormChange = () => {
    const values = form.getFieldsValue();
    if (debouncedSaveRef.current) {
      debouncedSaveRef.current(values);
    }
  };


  useEffect(() => {
    const fetchLSMQRData = async () => {
      try {
        const { data, error } = await supabase
          .from('milk_quality_consult_lsmqr_data')
          .select()
          .eq('milk_quality_consult_id', milkQualityConsultId);

        if (error) {
          throw new Error(`Error fetching existing LSMQR: ${error.message}`);
        }

        if (data && data.length > 0) {
          console.log('Existing LSMQR data:', data);
          if (!formInitialized) {
            form.setFieldsValue(data[0]);
            setCowMastitisEarly(data[0].cow_mastitis_early_1st_month || 0);
            setCowMastitisMid(data[0].cow_mastitis_mid_2nd_to_5th_month || 0);
            setCowMastitisLate(data[0].cow_mastitis_late_6th_to_10th_month || 0);
            setCowMastitisDry(data[0].cow_mastitis_dry_period || 0);
            setHeiferMastitisEarly(data[0].heifer_mastitis_early_1st_month || 0);
            setHeiferMastitisMid(data[0].heifer_mastitis_mid_2nd_to_5th_month || 0);
            setHeiferMastitisLate(data[0].heifer_mastitis_late_6th_to_10th_month || 0);
            setHeiferMastitisDry(data[0].heifer_mastitis_dry_period || 0);
            setFormInitialized(true);
          }
        } else {
          message.warning('No existing LSMQR data found.');
        }
      } catch (error) {
        console.error('Error fetching existing LSMQR:', error);
        message.error('Error fetching existing LSMQR. Please try again later.');
      }
    };

    const fetchFarmData = async () => {
      const { data: farmData, error: farmError } = await supabase
        .from('farms')
        .select('herd_size, heifer_size')
        .eq('id', farmId);

      if (farmError) {
        console.error('Error fetching farm data:', farmError);
        return;
      }

      if (farmData && farmData.length > 0) {
        if (farmData[0].herd_size > 0) {
          setHerdSize(farmData[0].herd_size);
        }
        if (farmData[0].heifer_size > 0) {
          setHeiferSize(farmData[0].heifer_size);
        }
      } else {
        console.log('No farm data found.');
      }
    }

    fetchFarmData();
    fetchLSMQRData();
  }, [farmId, milkQualityConsultId, form, formInitialized]);

  const onFinish = async (values) => {
    console.log(values)
    try {
      const { data: existingData, error: fetchError } = await supabase
        .from('milk_quality_consult_lsmqr_data')
        .select('id')
        .eq('milk_quality_consult_id', milkQualityConsultId)
        .limit(1);

      if (fetchError) {
        throw new Error(`Error checking existing LSMQR: ${fetchError.message}`);
      }

      if (existingData && existingData.length > 0) {
        // Update the existing record
        const { error: updateError } = await supabase
          .from('milk_quality_consult_lsmqr_data')
          .update(values)
          .eq('id', existingData[0].id);

        if (updateError) {
          throw new Error(`Error updating LSMQR: ${updateError.message}`);
        }

        message.success('LSMQR updated successfully');
      } else {
        // Generate a UUID for the new record
        const LSMQRId = uuidv4();

        // Insert a new record into the farm_objectives table
        const { error: insertError } = await supabase
          .from('milk_quality_consult_lsmqr_data')
          .insert([{ ...values, milk_quality_consult_id: milkQualityConsultId, id: LSMQRId }]);

        if (insertError) {
          throw new Error(`Error saving LSMQR: ${insertError.message}`);
        }

        // Update the corresponding record in the rvms table with the farm_objectives_id
        const { error: updateRvmError } = await supabase
          .from('milk_quality_consults')
          .update({ lsmqr_id: LSMQRId })
          .eq('id', milkQualityConsultId);

        if (updateRvmError) {
          throw new Error(`Error updating rvm with LSMQR id: ${updateRvmError.message}`);
        }

        message.success('LSMQR saved successfully');
      }
    } catch (error) {
      console.error('Error saving or updating LSMQR:', error);
      message.error('Error saving or updating LSMQR. Please try again later.');
    }
  };
  function createData(name, input1, input2, input3, input4, avg) {
    return { name, input1, input2, input3, input4, avg };
  }

  useEffect(() => {
    const cowTotal = cowMastitisEarly + cowMastitisMid + cowMastitisLate;
    setCowMastitisTotal(cowTotal);

    const heiferTotal = heiferMastitisEarly + heiferMastitisMid + heiferMastitisLate;
    setHeiferMastitisTotal(heiferTotal);
  }, [
    cowMastitisEarly,
    cowMastitisMid,
    cowMastitisLate,
    heiferMastitisEarly,
    heiferMastitisMid,
    heiferMastitisLate,
  ]);

  const rows = [
    createData(
      'BMSCC',
      'bmscc_early_1st_month',
      'bmscc_mid_2nd_to_5th_month',
      'bmscc_late_6th_to_10th_month',
      null,
      'bmscc_average'
    ),
    createData(
      'Clinical Mastitis - Cows',
      'cow_mastitis_early_1st_month',
      'cow_mastitis_mid_2nd_to_5th_month',
      'cow_mastitis_late_6th_to_10th_month',
      'cow_mastitis_dry_period',
      'cow_mastitis_average'
    ),
    createData(
      'Clinical Mastitis - Heifers',
      'heifer_mastitis_early_1st_month',
      'heifer_mastitis_mid_2nd_to_5th_month',
      'heifer_mastitis_late_6th_to_10th_month',
      'heifer_mastitis_dry_period',
      'heifer_mastitis_average'
    ),
    createData(
      '% Cows with iSCC < 150,000',
      'cow_iscc_early_1st_month',
      'cow_iscc_mid_2nd_to_5th_month',
      'cow_iscc_late_6th_to_10th_month',
      null,
      'cow_iscc_average'
    ),
    createData(
      '% Heifers with iSCC < 150,000',
      'heifer_iscc_bmscc_early_1st_month',
      'heifer_iscc_mid_2nd_to_5th_month',
      'heifer_iscc_late_6th_to_10th_month',
      null,
      null
    ),
  ];

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: 'calc(12px + var(--Header-height))',
          sm: 'calc(12px + var(--Header-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={handleFormChange}
        wrapperCol={{ span: 14 }}
        layout="vertical"
      >

        <Table variant="soft" borderAxis="bothBetween">
          <thead>
            <tr>
              <th style={{ width: '40%', textAlign: 'center' }}>{null}</th>
              <th style={{ width: '20%', textAlign: 'center' }}>Early - 1st Month</th>
              <th style={{ width: '20%', textAlign: 'center' }}>Mid - 2nd to 5th Month</th>
              <th style={{ width: '20%', textAlign: 'center' }}>Late - 6th to 10th Month</th>
              <th style={{ width: '20%', textAlign: 'center' }}>Average/Total</th>
              <th style={{ width: '20%', textAlign: 'center' }}>Dry period</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.name}>
                  <th scope="row" style={{ textAlign: 'center' }}>{row.name}</th>
                  <td style={{ textAlign: 'center' }}>
                    {row.name === 'Clinical Mastitis - Cows' ? (
                      <FormItem name={row.input1}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={cowMastitisEarly}
                          onChange={(value) => {
                            setCowMastitisEarly(value);
                            form.setFieldsValue({ [row.input1]: value });
                          }}
                        />
                        {herdSize !== 0 && (
                          <Text>
                            {((cowMastitisEarly / herdSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : row.name === 'Clinical Mastitis - Heifers' ? (
                      <FormItem name={row.input1}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={heiferMastitisEarly}
                          onChange={(value) => {
                            setHeiferMastitisEarly(value);
                            form.setFieldsValue({ [row.input1]: value });
                          }}
                        />
                        {heiferSize !== 0 && (
                          <Text>
                            {((heiferMastitisEarly / heiferSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : (
                      <FormItem name={row.input1}>
                        <InputNumber onChange={(value) => form.setFieldsValue({ [row.input1]: value })} />
                      </FormItem>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {row.name === 'Clinical Mastitis - Cows' ? (
                      <FormItem name={row.input2}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={cowMastitisMid}
                          onChange={(value) => {
                            setCowMastitisMid(value);
                            form.setFieldsValue({ [row.input2]: value });
                          }}
                        />
                        {herdSize !== 0 && (
                          <Text>
                            {((cowMastitisMid / herdSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : row.name === 'Clinical Mastitis - Heifers' ? (
                      <FormItem name={row.input2}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={heiferMastitisMid}
                          onChange={(value) => {
                            setHeiferMastitisMid(value);
                            form.setFieldsValue({ [row.input2]: value });
                          }}
                        />
                        {heiferSize !== 0 && (
                          <Text>
                            {((heiferMastitisMid / heiferSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : (
                      <FormItem name={row.input2}>
                        <InputNumber onChange={(value) => form.setFieldsValue({ [row.input2]: value })} />
                      </FormItem>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {row.name === 'Clinical Mastitis - Cows' ? (
                      <FormItem name={row.input3}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={cowMastitisLate}
                          onChange={(value) => {
                            setCowMastitisLate(value);
                            form.setFieldsValue({ [row.input3]: value });
                          }}
                        />
                        {herdSize !== 0 && (
                          <Text>
                            {((cowMastitisLate / herdSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : row.name === 'Clinical Mastitis - Heifers' ? (
                      <FormItem name={row.input3}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={heiferMastitisLate}
                          onChange={(value) => {
                            setHeiferMastitisLate(value);
                            form.setFieldsValue({ [row.input3]: value });
                          }}
                        />
                        {heiferSize !== 0 && (
                          <Text>
                            {((heiferMastitisLate / heiferSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : (
                      <FormItem name={row.input3}>
                        <InputNumber onChange={(value) => form.setFieldsValue({ [row.input3]: value })} />
                      </FormItem>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {row.avg && row.name === 'Clinical Mastitis - Cows' ? (
                      <FormItem name={row.avg} style={{ textAlign: 'center' }}>
                        <InputNumber value={cowMastitisTotal} readOnly />
                        {herdSize !== 0 && (
                          <Text>
                            {((cowMastitisTotal / herdSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : row.avg && row.name === 'Clinical Mastitis - Heifers' ? (
                      <FormItem name={row.avg} style={{ textAlign: 'center' }}>
                        <InputNumber value={heiferMastitisTotal} readOnly />
                        {heiferSize !== 0 && (
                          <Text>
                            {((heiferMastitisTotal / heiferSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : row.avg ? (
                      <FormItem name={row.avg} style={{ textAlign: 'center' }}>
                        <InputNumber />
                      </FormItem>
                    ) : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {row.name === 'Clinical Mastitis - Cows' && row.input4 ? (
                      <FormItem name={row.input4}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={cowMastitisDry}
                          onChange={(value) => {
                            setCowMastitisDry(value);
                            form.setFieldsValue({ [row.input4]: value });
                          }}
                        />
                        {herdSize !== 0 && (
                          <Text>
                            {((cowMastitisDry / herdSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : row.name === 'Clinical Mastitis - Heifers' && row.input4 ? (
                      <FormItem name={row.input4}>
                        <InputNumber
                          style={{ width: '100%' }}
                          value={heiferMastitisDry}
                          onChange={(value) => {
                            setHeiferMastitisDry(value);
                            form.setFieldsValue({ [row.input4]: value });
                          }}
                        />
                        {heiferSize !== 0 && (
                          <Text>
                            {((heiferMastitisDry / heiferSize) * 100).toFixed(2)}%
                          </Text>
                        )}
                      </FormItem>
                    ) : row.input4 ? (
                      <FormItem name={row.input4}>
                        <InputNumber onChange={(value) => form.setFieldsValue({ [row.input4]: value })} />
                      </FormItem>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* </Box> */}
        <Box >
          <FormItem label={'Number of cows with repeated mastitis treatments:'} name={'number_of_cows_with_repeated_mastitis_treatments'}>
            <InputNumber sx={{ width: '100%' }} />
          </FormItem>
          <FormItem label={'Number of cows culled for mastitis or high SCC:'} name={'number_of_cows_culled_for_mastitis_or_scc'}>
            <InputNumber />
          </FormItem>
          <FormItem label={'Primary pathogens detected: '} name={'pathogens_detected'}>
            <Select allowClear
              placeholder={'Select an option'}
              mode='multiple'
              options={[
                { value: 'environmental', label: 'Enviromental' },
                { value: 'commensal', label: 'Commensal' },
                { value: 'contagious', label: 'Contagious' },
              ]}
            />
          </FormItem>
          <FormItem label={'Mastitis control measures in place: '} name={'mastitis_control_measures'}>
            <Select allowClear
              placeholder={'Select an option'}
              mode='multiple'
              options={[
                { value: 'Machine test', label: 'Machine test' },
                { value: 'Teat scoring', label: 'Teat scoring' },
                { value: 'Linear changes within recommendations', label: 'Linear changes within recommendations' },
                { value: 'Teat spray - every cow, every milking', label: 'Teat spray - every cow, every milking' },
                { value: 'Detection of clinical mastitis', label: 'Detection of clinical mastitis' },
                { value: 'Environmental risk assessment (yard, races, feedpad, crossings)', label: 'Environmental risk assessment (yard, races, feedpad, crossings)' },
              ]}
            />
          </FormItem>
        </Box>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </Box>
  )
}