import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, Flex, Tabs, message } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import supabase from '~supabaseConfig';
import HealthPlannerTable from './components/HealthPlannerTable';
import HealthPlannerForm from './components/HealthPlannerForm';
import ProductTable from './components/ProductTable';
import ProductForm from './components/ProductForm';
import HealthCostsTable from './components/HealthCostsTable';
import HealthCostsForm from './components/HealthCostsForm';

const CombinedSettings = () => {
    // Add new state for health costs
    const [healthItems, setHealthItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [healthCosts, setHealthCosts] = useState([]); // New state
    const [healthForm] = Form.useForm();
    const [productForm] = Form.useForm();
    const [healthCostsForm] = Form.useForm(); // New form
    const [editingId, setEditingId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeTab, setActiveTab] = useState('health');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchHealthItems();
        fetchProducts();
        fetchHealthCosts(); // Add new fetch
    }, []);

    // Add new fetch function for health costs
    const fetchHealthCosts = async () => {
        setLoading(true);
        const { data, error } = await supabase
            .from('animal_health_costs')
            .select('*')
            .order('created_at', { ascending: false });

        if (error) {
            message.error('Error fetching health costs: ' + error.message);
        } else {
            setHealthCosts(data);
        }
        setLoading(false);
    };

    // Existing fetch functions remain the same
    const fetchHealthItems = async () => {
        setLoading(true);
        try {
            // First fetch the health planner items
            const { data: healthItems, error: healthError } = await supabase
                .from('animal_health_planner_items')
                .select('*')
                .order('position');

            if (healthError) throw healthError;

            // For items with product_refs, fetch the product details
            const itemsWithProducts = await Promise.all(
                healthItems.map(async (item) => {
                    if (item.product_refs && item.product_refs.length > 0) {
                        const { data: products, error: productsError } = await supabase
                            .from('animal_health_stock') // Changed from 'products' to match your table name
                            .select('code, product_name, price')
                            .in('code', item.product_refs);

                        if (productsError) throw productsError;

                        return {
                            ...item,
                            products: products
                        };
                    }
                    return item;
                })
            );

            setHealthItems(itemsWithProducts);
        } catch (error) {
            message.error('Error fetching items: ' + error.message);
        }
        setLoading(false);
    };

    const fetchProducts = async () => {
        setLoading(true);
        const { data, error } = await supabase
            .from('animal_health_stock')
            .select('*')
            .order('product_name');

        if (error) {
            message.error('Error fetching products: ' + error.message);
        } else {
            setProducts(data);
        }
        setLoading(false);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Update filtered items to include costs
    const filteredItems = {
        health: healthItems.filter(item =>
            item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.category?.toLowerCase().includes(searchQuery.toLowerCase())
        ),
        products: products.filter(product =>
            product.product_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.category?.toLowerCase().includes(searchQuery.toLowerCase())
        ),
        costs: healthCosts.filter(cost =>
            cost.name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
    };

    // Update handle finish to include costs
    const handleFinish = async (values) => {
        try {
            if (activeTab === 'health') {
                console.log('Product refs:', values.product_refs);
                console.log('Products:', values.products);

                let finalPrice = values.price;
                console.log('Initial price:', finalPrice);

                if (values.product_refs?.length > 0 && values.products) {
                    finalPrice = values.products.reduce((sum, prod) => {
                        console.log('Adding product price:', prod.price);
                        return sum + Number(prod.price);
                    }, 0);
                }
                console.log('Final calculated price:', finalPrice);

                const payload = {
                    ...values,
                    add_to_cost: values.add_to_cost || [],
                    product_refs: values.product_refs || [],
                    price: finalPrice,
                    // Include the products array for reference
                    products: values.product_refs?.length > 0 ? values.products : []
                };

                if (editingId) {
                    const { error } = await supabase
                        .from('animal_health_planner_items')
                        .update(payload)
                        .eq('id', editingId);
                    if (error) throw error;
                    message.success('Health item updated successfully');
                } else {
                    const { error } = await supabase
                        .from('animal_health_planner_items')
                        .insert(payload);
                    if (error) throw error;
                    message.success('Health item added successfully');
                }
                await fetchHealthItems(); // Ensure fresh data is loaded
            } else if (activeTab === 'products') {
                if (editingId) {
                    const { error } = await supabase
                        .from('animal_health_stock')
                        .update(values)
                        .eq('id', editingId);
                    if (error) throw error;
                    message.success('Product updated successfully');
                } else {
                    const { error } = await supabase
                        .from('animal_health_stock')
                        .insert(values);
                    if (error) throw error;
                    message.success('Product added successfully');
                }
                fetchProducts();
            } else if (activeTab === 'costs') {
                if (editingId) {
                    const { error } = await supabase
                        .from('animal_health_costs')
                        .update(values)
                        .eq('id', editingId);
                    if (error) throw error;
                    message.success('Health cost updated successfully');
                } else {
                    const { error } = await supabase
                        .from('animal_health_costs')
                        .insert(values);
                    if (error) throw error;
                    message.success('Health cost added successfully');
                }
                fetchHealthCosts();
            }

            setIsModalVisible(false);
            setEditingId(null);
            healthForm.resetFields();
            productForm.resetFields();
            healthCostsForm.resetFields();
        } catch (error) {
            message.error('Error: ' + error.message);
        }
    };

    const handleDelete = (id) => {
        setItemToDelete(id);
        setIsDeleteModalVisible(true);
    };
    // Update handle delete to include costs
    const handleDeleteConfirm = async () => {
        try {
            const tableName = activeTab === 'health'
                ? 'animal_health_planner_items'
                : activeTab === 'products'
                    ? 'animal_health_stock'
                    : 'animal_health_costs';

            const { error } = await supabase
                .from(tableName)
                .delete()
                .eq('id', itemToDelete);

            if (error) throw error;

            message.success(`${activeTab === 'health' ? 'Health item' :
                activeTab === 'products' ? 'Product' :
                    'Health cost'
                } deleted successfully`);

            setIsDeleteModalVisible(false);

            if (activeTab === 'health') {
                fetchHealthItems();
            } else if (activeTab === 'products') {
                fetchProducts();
            } else {
                fetchHealthCosts();
            }
        } catch (error) {
            message.error('Error deleting item: ' + error.message);
        }
    };

    const handleEdit = (record) => {
        setEditingId(record.id);
        if (activeTab === 'health') {
            healthForm.setFieldsValue(record);
        } else if (activeTab === 'products') {
            productForm.setFieldsValue(record);
        } else {
            healthCostsForm.setFieldsValue(record);
        }
        setIsModalVisible(true);
    };

    // Update tab items to include costs
    const items = [
        {
            key: 'health',
            label: 'Health Items',
            children: (
                <HealthPlannerTable
                    items={filteredItems.health}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    loading={loading}
                />
            ),
        },
        {
            key: 'products',
            label: 'Products',
            children: (
                <ProductTable
                    items={filteredItems.products}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    loading={loading}
                />
            ),
        },
        {
            key: 'costs',
            label: 'Health Costs',
            children: (
                <HealthCostsTable
                    items={filteredItems.costs}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    loading={loading}
                />
            ),
        },
    ];

    // Add function to get the appropriate form
    const getModalContent = () => {
        switch (activeTab) {
            case 'health':
                return (
                    <HealthPlannerForm
                        form={healthForm}
                        onFinish={handleFinish}
                        editingId={editingId}
                    />
                );
            case 'products':
                return (
                    <ProductForm
                        form={productForm}
                        onFinish={handleFinish}
                        editingId={editingId}
                    />
                );
            case 'costs':
                return (
                    <HealthCostsForm
                        form={healthCostsForm}
                        onFinish={handleFinish}
                        editingId={editingId}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="p-5">
            <div className="mb-6">
                <h1 className="text-2xl font-bold mb-4">Settings</h1>
                <Flex className="justify-between items-center">
                    <div className="relative w-64">
                        <Input
                            prefix={<SearchOutlined className="mr-2" />}
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder={`Search ${activeTab === 'health' ? 'health items' :
                                activeTab === 'products' ? 'products' :
                                    'health costs'
                                }...`}
                            className="w-full"
                        />
                    </div>
                    <Button
                        type="primary"
                        icon={<PlusOutlined className="w-4 h-4" />}
                        onClick={() => setIsModalVisible(true)}
                        className="flex items-center"
                    >
                        Add {
                            activeTab === 'health' ? 'Health Item' :
                                activeTab === 'products' ? 'Product' :
                                    'Health Cost'
                        }
                    </Button>
                </Flex>
            </div>

            <Tabs
                items={items}
                activeKey={activeTab}
                onChange={setActiveTab}
            />

            <Modal
                title={`${editingId ? "Edit" : "Add New"} ${activeTab === 'health' ? 'Health Item' :
                    activeTab === 'products' ? 'Product' :
                        'Health Cost'
                    }`}
                open={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    setEditingId(null);
                    healthForm.resetFields();
                    productForm.resetFields();
                    healthCostsForm.resetFields();
                }}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => {
                            setIsModalVisible(false);
                            setEditingId(null);
                            healthForm.resetFields();
                            productForm.resetFields();
                            healthCostsForm.resetFields();
                        }}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            switch (activeTab) {
                                case 'health':
                                    healthForm.submit();
                                    break;
                                case 'products':
                                    productForm.submit();
                                    break;
                                case 'costs':
                                    healthCostsForm.submit();
                                    break;
                            }
                        }}
                    >
                        {editingId ? "Update" : "Add"}
                    </Button>
                ]}
            >
                {getModalContent()}
            </Modal>

            <Modal
                title="Confirm Delete"
                open={isDeleteModalVisible}
                onOk={handleDeleteConfirm}
                onCancel={() => {
                    setIsDeleteModalVisible(false);
                    setItemToDelete(null);
                }}
                okText="Yes, delete it"
                cancelText="No, keep it"
            >
                <p>Are you sure you want to delete this {
                    activeTab === 'health' ? 'health item' :
                        activeTab === 'products' ? 'product' :
                            'health cost'
                }? This action cannot be undone.</p>
            </Modal>
        </div>
    );
};

export default CombinedSettings;