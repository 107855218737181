import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import supabase from '~supabaseConfig'; // Make sure this path is correct

const App = () => {
 const [current, setCurrent] = useState('Back');
 const [isSuperAdmin, setIsSuperAdmin] = useState(false);

 useEffect(() => {
   checkUserRole();
 }, []);

 const checkUserRole = async () => {
   const { data: { user } } = await supabase.auth.getUser();
   if (user) {
     // Fetch the user's role from your users table
     const { data, error } = await supabase
       .from('users') // replace with your actual table name
       .select('*')
       .eq('id', user.id)
       .single();

       console.log(data)
     if (data && data.super_admin === true) {
       setIsSuperAdmin(true);
     }
   }
 };

 const baseItems = [
  {
   label: <Link to={'/'}>
    <ArrowBackIosIcon />
   </Link>,
   key: 'Back',
  },
  {
   label: <Link to={`/settings/vets`}>Vets list</Link>,
   key: 'vets',
  },
  {
   label: <Link to={`/settings/animal-health`}>Animal health settings</Link>,
   key: 'animalHealth',
  },
  {
   label: <Link to={`/settings/animal-health-planner`}>Animal health planner settings</Link>,
   key: 'animalHealthPlanner',
  },
  {
   label: <Link to={`/settings/rvm-medication`}>RVM medication</Link>,
   key: 'rvm-medication',
  },
 ];

 const superAdminItems = [
  {
   label: <Link to={`/settings/admin`}>Admin settings</Link>,
   key: 'admin',
  },
 ];

 const items = isSuperAdmin ? [...baseItems, ...superAdminItems] : baseItems;

 const onClick = (e) => {
  setCurrent(e.key);
 };

 return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};

export default App;