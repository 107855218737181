import supabase from '~supabaseConfig';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';


async function fetchDataMatchingNames(tableName, nameArray) {
    if (!Array.isArray(nameArray) || nameArray.length === 0) {
        console.error('Invalid nameArray. It must be a non-empty array.');
        return null;
    }

    try {
        const { data, error } = await supabase
            .from(tableName)
            .select('*')
            .in('name', nameArray);

        if (error) {
            console.error('Error fetching data:', error.message);
            return null;
        }

        return data;
    } catch (err) {
        console.error('Unexpected error during fetch:', err);
        return null;
    }
}

async function fetchHerdMatchingNames(tableName, nameArray, consult) {
    console.log('fetchHerdMatchingNames called with:', nameArray);

    if (!Array.isArray(nameArray) || nameArray.length === 0) {
        console.error('Invalid nameArray. It must be a non-empty array.');
        return null;
    }

    // Convert all items in nameArray to lowercase
    const lowercaseNameArray = nameArray.map(name => name.toLowerCase());

    console.log('Lowercase nameArray:', lowercaseNameArray);

    try {
        const { data, error } = await supabase
            .from(tableName)
            .select('*')
            .in('name', lowercaseNameArray)
            .eq(consult, true);

        if (error) {
            console.error('Error fetching data from', tableName, ':', error.message);
            return null;
        }

        console.log('Fetched data:', data);
        return data;
    } catch (err) {
        console.error('Unexpected error during fetch from', tableName, ':', err);
        return null;
    }
}

const calculatePrice = (item, herdSizes = {}) => {
    let finalPrice = 0;

    // Case A: Direct price
    if (item.price) {
        return parseFloat(item.price);
    }

    // Case B: Base price with multiple products
    if (item.product_refs && item.product_multipliers && item.products) {
        finalPrice = Object.entries(item.product_multipliers).reduce((sum, [productId, multiplier]) => {
            const product = item.products.find(p => p.id === productId);
            return product ? sum + (product.price * multiplier) : sum;
        }, 0);
    }

    // Add additional costs
    if (item.additional_costs?.length) {
        const additionalTotal = item.additional_costs.reduce((sum, cost) => {
            const multiplier = item.cost_multipliers?.[cost.id] || 1;
            return sum + (cost.price * multiplier);
        }, 0);
        finalPrice += additionalTotal;
    }

    // Apply herd size multiplier
    if (item.multiply_by) {
        const sizeValue = herdSizes[item.multiply_by.toLowerCase().replace(' ', '_')] || 1;
        finalPrice *= sizeValue;
    }

    // Apply final multiplier
    if (item.multiply_factor) {
        finalPrice *= parseFloat(item.multiply_factor);
    }

    return parseFloat(finalPrice.toFixed(3));
};

export default function AnimalHealthPlanner() {
    const { farmId, rvmId } = useParams();
    const [rvmData, setRvmData] = useState(null);
    const [milkQualityData, setMilkQualityData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rvmMilkQualityServicesItems, setrvmMilkQualityServicesItems] = useState([]);
    const [rvmReproServicesItems, setRvmReproServicesItems] = useState([]);
    const [rvmHealthServicesItems, setRvmHealthServicesItems] = useState([]);
    const [milkQualityServicesItems, setMilkQualityServicesItems] = useState([]);
    const [rvmBvdTestingItems, setRvmBvdTestingItems] = useState([]);
    const [rvmBvdVaccinationItems, setRvmBvdVaccinationItems] = useState([]);
    const [herdSizes, setHerdSizes] = useState({});
    const [heiferSizes, setHeiferSizes] = useState({});
    const [calfSizes, setCalfSizes] = useState({});
    const [plannerDates, setPlannerDates] = useState({});
    const [existingPlannerData, setExistingPlannerData] = useState(null);

    const fetchRvmData = async () => {
        try {
            const { data, error } = await supabase
                .from('rvms')
                .select(`
                    start_date,
                    end_date,
                    farms:farm_id (
                        id, farm_name, farm_size, email, phone_number,
                        address, herd_size, heifer_size, farmer_name,
                        clinic_id (id, clinic_name, address),
                        vet (id, name, email),
                        supply_number
                    ),
                    milk_quality_data_id (services),
                    repro_id (
                        scanning, services, herd_testing,
                        herd_animals_tested, herd_animals_vaccinated
                    ),
                    health_id (services),
                    farm_objectives_id (
                        planned_start_of_calving_spring,
                        planned_start_of_calving_autumn,
                        herd_size,
                        calf_herd_size,
                        heifer_size
                    )
                `)
                .eq('id', rvmId)
                .single();

            if (error) throw error;
            if (!data) throw new Error(`No data found for rvmId ${rvmId}`);

            setRvmData(data);
            setLoading(false);
            console.log('RVM data:', data);

            if (data.start_date && data.end_date) {
                fetchMilkQualityData(data.start_date, data.end_date);
            }
            if (data.farm_objectives_id) {
                setHerdSizes({
                    herd_size: data.farm_objectives_id.herd_size,
                    calf_size: data.farm_objectives_id.calf_herd_size,
                    heifer_size: data.farm_objectives_id.heifer_size
                });
            }
        } catch (error) {
            console.error('Error fetching RVM data:', error);
            setLoading(false);
        }
    };

    const fetchMilkQualityData = async (startDate, endDate) => {
        try {
            const { data, error } = await supabase
                .from('milk_quality_consults')
                .select(`
                    dry_cow_id(recommended_follow_up)
                    `)
                .eq('farm_id', farmId)
                .gte('start_date', startDate)
                .lte('end_date', endDate);

            if (error) throw error;
            if (!data) throw new Error(`No data found for farmId ${farmId}`);

            setMilkQualityData(data);
            if (data) {
                console.log('Milk Quality data:', data);
            }
        } catch (error) {
            console.error('Error fetching Milk Quality data:', error);
        }
    };

    // Process RVM data only when it changes
    useEffect(() => {
        if (!rvmData) return;

        const processAllServices = async () => {
            try {
                // Process milk quality services
                if (rvmData.milk_quality_data_id?.services) {
                    const milkQualityItems = await fetchDataMatchingNames(
                        'animal_health_planner_items',
                        rvmData.milk_quality_data_id.services
                    );
                    setrvmMilkQualityServicesItems(milkQualityItems || []);
                }

                // Process health services
                if (rvmData.health_id?.services) {
                    const healthItems = await fetchDataMatchingNames(
                        'animal_health_planner_items',
                        rvmData.health_id.services
                    );
                    setRvmHealthServicesItems(healthItems || []);
                }

                // Process repro services
                if (rvmData.repro_id?.services) {
                    const reproItems = await fetchDataMatchingNames(
                        'animal_health_planner_items',
                        rvmData.repro_id.services
                    );
                    setRvmReproServicesItems(reproItems || []);
                }

                // Process milk quality services
                if (milkQualityData) {
                    const milkQualityItems = await fetchDataMatchingNames(
                        'animal_health_planner_items',
                        milkQualityData[0].dry_cow_id?.recommended_follow_up
                    );
                    setMilkQualityServicesItems(milkQualityItems || []);
                }

                // Process BVD testing services
                if (rvmData.repro_id?.herd_animals_tested) {
                    const bvdTestingItems = await fetchHerdMatchingNames(
                        'animal_health_planner_items',
                        rvmData.repro_id.herd_animals_tested,
                        'testing'
                    );
                    setRvmBvdTestingItems(bvdTestingItems || []);
                }

                // Process BVD vaccination services
                if (rvmData.repro_id?.herd_animals_vaccinated) {
                    const bvdVaccinationItems = await fetchHerdMatchingNames(
                        'animal_health_planner_items',
                        rvmData.repro_id.herd_animals_vaccinated,
                        'vaccination'
                    );
                    setRvmBvdVaccinationItems(bvdVaccinationItems || []);
                }
            } catch (error) {
                console.error('Error processing services:', error);
            }
        };

        processAllServices();
    }, [rvmData]);

    // Initial data fetch
    useEffect(() => {
        if (rvmId) {
            fetchRvmData();
        }
    }, [rvmId, farmId]);

    useEffect(() => {
        if (rvmData && !loading) {
            // Log prices for each category
            rvmMilkQualityServicesItems?.forEach(item => {
                console.log(`Milk Quality Service: ${item.name}`, calculatePrice(item, herdSizes));
                console.log(item)
            });

            rvmReproServicesItems?.forEach(item => {
                console.log(`Repro Service: ${item.name}`, calculatePrice(item, herdSizes));
            });

            rvmHealthServicesItems?.forEach(item => {
                console.log(`Health Service: ${item.name}`, calculatePrice(item, herdSizes));
            });

            rvmBvdTestingItems?.forEach(item => {
                console.log(`BVD Testing: ${item.name}`, calculatePrice(item, herdSizes));
            });

            rvmBvdVaccinationItems?.forEach(item => {
                console.log(`BVD Vaccination: ${item.name}`, calculatePrice(item, herdSizes));
            });
        }
    }, [
        rvmData,
        loading,
        herdSizes,
        rvmMilkQualityServicesItems,
        rvmReproServicesItems,
        rvmHealthServicesItems,
        rvmBvdTestingItems,
        rvmBvdVaccinationItems
    ]);

    const calculateCategoryTotal = (data) => {
        return data.reduce((sum, item) => sum + (item.finalPrice || 0), 0);
    };

    const calculateOverallTotal = (categories) => {
        return categories.reduce((sum, category) => sum + calculateCategoryTotal(category.data), 0);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Price',
            dataIndex: 'finalPrice',
            key: 'finalPrice',
            render: (price) => price?.toFixed(2) || '-',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (_, record) => (
                <input
                    type="date"
                    onChange={(e) => handleDateChange(record.name, e.target.value)}
                    value={plannerDates[record.name] || ''}
                    className="border p-2 rounded"
                />
            ),
        }
    ];

    const generateTableData = (items) => {
        return items?.map(item => ({
            key: item.id,
            name: item.name,
            title: item.title,
            finalPrice: item.final_price || calculatePrice(item, herdSizes)
        })) || [];
    };

    const fetchExistingPlannerData = async () => {
        const { data, error } = await supabase
            .from('rvm_animal_health_planner_data')
            .select('*')
            .eq('rvm_id', rvmId)
            .single();

        if (data) {
            const dates = {};
            Object.entries(data).forEach(([key, value]) => {
                if (key.endsWith('_date') && value) {
                    const itemName = key.replace('_date', '');
                    dates[itemName] = value;
                }
            });
            setPlannerDates(dates);
            setExistingPlannerData(data);
        }
    };

    const handleDateChange = async (itemName, date) => {
        const columnName = `${itemName}_date`;

        setPlannerDates(prev => ({
            ...prev,
            [itemName]: date
        }));

        const updateData = {
            rvm_id: rvmId,
            [columnName]: date
        };

        if (existingPlannerData?.id) {
            const { error } = await supabase
                .from('rvm_animal_health_planner_data')
                .update(updateData)
                .eq('id', existingPlannerData.id);

            if (error) console.error('Error updating:', error);
        } else {
            const { error } = await supabase
                .from('rvm_animal_health_planner_data')
                .insert([updateData]);

            if (error) console.error('Error inserting:', error);
        }
    };

    useEffect(() => {
        if (rvmId) {
            fetchExistingPlannerData();
        }
    }, [rvmId]);

    const categoryTables = [
        {
            title: 'Milk Quality Services',
            data: generateTableData(rvmMilkQualityServicesItems)
        },
        {
            title: 'Reproduction Services',
            data: generateTableData(rvmReproServicesItems)
        },
        {
            title: 'Health Services',
            data: generateTableData(rvmHealthServicesItems)
        },
        {
            title: 'BVD Testing',
            data: generateTableData(rvmBvdTestingItems)
        },
        {
            title: 'BVD Vaccination',
            data: generateTableData(rvmBvdVaccinationItems)
        }
    ];

    const overallTotal = calculateOverallTotal(categoryTables);

    const summaryColumns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (total) => `$${total.toFixed(2)}`,
        },
    ];

    const summaryData = categoryTables
        .filter(category => category.data.length > 0)
        .map(category => ({
            key: category.title,
            category: category.title,
            total: calculateCategoryTotal(category.data),
        }));


    const summaryTableData = [
        ...summaryData,
        {
            key: 'final',
            category: <strong>Final Total</strong>,
            total: overallTotal,
        },
    ];

    return (
        <div className="space-y-8">
            {categoryTables.map((category, index) => (
                category.data.length > 0 && (
                    <div key={index} className="bg-white rounded-lg shadow p-6">
                        <h2 className="text-xl font-semibold mb-4">{category.title}</h2>
                        <Table
                            columns={columns}
                            dataSource={category.data}
                            pagination={false}
                            summary={() => (
                                <Table.Summary>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={2}>
                                            <strong>Total</strong>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>
                                            <strong>${calculateCategoryTotal(category.data).toFixed(2)}</strong>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} />
                                    </Table.Summary.Row>
                                </Table.Summary>
                            )}
                        />
                    </div>
                )
            ))}

            <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold mb-4">Summary</h2>
                <Table
                    columns={summaryColumns}
                    dataSource={summaryTableData}
                    pagination={false}
                    summary={null}
                    className="summary-table"
                />
            </div>
        </div>
    );
};
