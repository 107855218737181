import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ConsultNavbar from '../components/ConsultNavbar';
import ConsultBottomNav from '../components/ConsultBottomNav';
import excelValues from './components/rvm';
import supabase from '~supabaseConfig';

const cloudflareWorkerUrl = import.meta.env.VITE_CLOUDFLARE_WORKER_URL;

export const fetchRvmData = async (tableName, columnName, value) => {
    console.log(`Fetching data from ${tableName} where ${columnName} = ${value}`);
    try {
        const { data, error } = await supabase.from(tableName).select(`
            *,
            farms:farm_id (
                id,
                farm_name,
                farm_size,
                email,
                phone_number,
                address,
                herd_size,
                heifer_size,
                farmer_name,
                clinic_id (
                    id,
                    clinic_name,
                    address
                ),
                vet (
                    id,
                    name,
                    email
                ),
                supply_number
            ),
            farm_objectives_id (*),
            key_actions_id (*),
            milk_quality_data_id (*),
            mineral_supplementation_id (*),
            repro_id (*),
            health_id (*),
            rvm_id (*)
        `)
        .eq(columnName, value)
        .single();

        if (error) throw error;
        if (!data) throw new Error(`No data found for ${columnName} ${value} in ${tableName}`);
        return data;
    } catch (error) {
        console.error(`Error in fetchWellbeingData for ${tableName}:`, error);
        throw error;
    }
};

const RvmLayout = () => {
    const { farmId, rvmId } = useParams();
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        const getCurrentUser = async () => {
            console.log('Fetching current user session...');
            const { data: { session }, error } = await supabase.auth.getSession();
            
            if (error) {
                console.error('Error fetching user session:', error);
                return;
            }

            if (session?.user) {
                const email = session.user.email;
                console.log('Current user email:', email);
                console.log('Is user authorized for Animal Health Planner:', email === 'hhenare@fvs.co.nz');
                setUserEmail(email);
            } else {
                console.log('No active user session found');
            }
        };
        getCurrentUser();
    }, []);

    const baseNavItems = [
        {
            label: <Link to={`/farm/${farmId}/`}><ArrowBackIosIcon /></Link>,
            key: 'Back',
        },
        {
            label: <Link to={`/farm/${farmId}/rvm/${rvmId}/FarmObjectives`}>Farm Objectives</Link>,
            key: 'FarmObjectives',
        },
        {
            label: <Link to={`/farm/${farmId}/rvm/${rvmId}/MilkQualityData`}>Milk Quality Data</Link>,
            key: 'MilkQualityData',
        },
        {
            label: <Link to={`/farm/${farmId}/rvm/${rvmId}/Repro`}>Repro</Link>,
            key: 'Repro',
        },
        {
            label: <Link to={`/farm/${farmId}/rvm/${rvmId}/Health`}>Health</Link>,
            key: 'Health',
        },
        {
            label: <Link to={`/farm/${farmId}/rvm/${rvmId}/MineralSupplementation`}>Mineral Supplementation</Link>,
            key: 'MineralSupplementation',
        },
        {
            label: <Link to={`/farm/${farmId}/rvm/${rvmId}/KeyActions`}>Key Actions</Link>,
            key: 'KeyActions',
        },
        {
            label: <Link to={`/farm/${farmId}/rvm/${rvmId}/RVM`}>RVM</Link>,
            key: 'RVM',
        },
    ];

    const animalHealthPlannerItem = {
        label: <Link to={`/farm/${farmId}/rvm/${rvmId}/AnimalHealthPlanner`}>Animal Health Planner</Link>,
        key: 'AnimalHealthPlanner',
    };

    const navItems = userEmail === 'hhenare@fvs.co.nz' 
        ? [...baseNavItems, animalHealthPlannerItem]
        : baseNavItems;

    console.log('Navigation items count:', navItems.length);
    console.log('Animal Health Planner visible:', navItems.length > baseNavItems.length);

    return (
        <>
            <ConsultNavbar
                items={navItems}
                consultType="rvm"
                consultIdParam="rvmId"
                supabaseTable="rvms"
            />
            <Outlet />
            <ConsultBottomNav
                consultType="rvm"
                consultIdParam="rvmId"
                supabaseTable="rvms"
                cloudflareWorkerUrl={cloudflareWorkerUrl}
                excelGenerator={excelValues}
                reportName="RVM"
                fetchDataFunction={fetchRvmData}
            />
        </>
    );
};

export default RvmLayout;