import React, { useEffect, useState, useCallback } from 'react';
import { Table, Spin, Input, Form, Button, message, InputNumber, Divider } from 'antd';
import supabase from '~supabaseConfig';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';
import excelValues from './rvmMeds.js';
import { debounce } from 'lodash';
const cloudflareWorkerUrl = import.meta.env.VITE_CLOUDFLARE_WORKER_AUTH_URL;

const fetchData = async (tableName, columnName, value) => {
  try {
    const { data, error } = await supabase.from(tableName).select().eq(columnName, value).limit(1);
    if (error) {
      console.error(`Error fetching data from ${tableName}:`, error);
      throw new Error(`Error fetching data from ${tableName}`);
    }
    if (data.length === 0) {
      console.warn(`No data found for ${columnName} ${value} in ${tableName}`);
      throw new Error(`No data found for ${columnName} ${value} in ${tableName}`);
    }
    console.log(`Successfully fetched data from ${tableName}`);
    return data[0];
  } catch (error) {
    console.error(`Error in fetchData for ${tableName}:`, error);
    message.error({
      content: `Error fetching data from ${tableName}: ${error.message}`,
      style: {
        marginTop: '100px',
        color: '#ff4d4f',
      },
    });
    throw error;
  }
}
const fetchRelatedData = async (tableName, rvmId) => {
  try {
    const { data, error } = await supabase.from(tableName).select('*').eq('rvm_id', rvmId);
    if (error) {
      console.error(`Error fetching related data from ${tableName}:`, error);
      throw new Error(`Error fetching related data from ${tableName}`);
    }
    console.log(`Successfully fetched related data from ${tableName}`);
    return data;
  } catch (error) {
    console.error(`Error in fetchRelatedData for ${tableName}:`, error);
    message.error({
      content: `Error fetching related data from ${tableName}: ${error.message}`,
      style: {
        marginTop: '100px',
        color: '#ff4d4f',
      },
    });
    throw error;
  }
};

const RvmItemsTable = () => {
  const [rvmItems, setRvmItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState('');
  const { rvmId } = useParams();
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const fetchRvmItemsData = async () => {
      try {
        const { data: rvmItemsData, error: rvmItemsError } = await supabase
          .from('rvm_items')
          .select();

        if (rvmItemsError) {
          console.error('Error fetching RvmItems:', rvmItemsError);
          return;
        }

        const { data: medicationData, error: medicationError } = await supabase
          .from('rvm_medication_data')
          .select()
          .eq('rvm_id', rvmId);

        if (medicationError) {
          console.error('Error fetching medication data:', medicationError);
          return;
        }

        const duplicateItemIds = medicationData
          .map(item => item.item_id)
          .filter((item, index, self) => self.indexOf(item) !== index);

        await Promise.all(duplicateItemIds.map(async (itemId) => {
          const { data, error } = await supabase
            .from('rvm_medication_data')
            .delete()
            .eq('item_id', itemId)
            .neq('id', medicationData.find(item => item.item_id === itemId).id);

          if (error) {
            console.error(`Error deleting duplicate item with item_id ${itemId}:`, error);
          } else {
            console.log(`Deleted duplicate item with item_id ${itemId}`);
          }
        }));

        const medicationDataWithoutDuplicates = removeDuplicates(medicationData, 'item_id');

        const updatedItems = medicationDataWithoutDuplicates.map(medication => ({
          ...medication,
          key: medication.id,
        }));

        setCurrentItems(updatedItems);

        if (rvmItemsData) {
          console.log(updatedItems);
          const prefillData = rvmItemsData.map(item => {
            const medicationItem = updatedItems.find(med => med.item_id === item.id);
            return {
              ...item,
              quantity_authorised: medicationItem?.quantity_authorised || null,
              quantity_on_farm: medicationItem?.quantity_on_farm || null,
              item_id: medicationItem?.item_id || null,
            };
          });

          try {
            setRvmItems(prefillData);
          } catch (error) {
            console.error('Error setting RvmItems:', error);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRvmItemsData();
  }, [rvmId]);


  function removeDuplicates(array, key) {
    return array.filter((obj, index, self) =>
      index === self.findIndex((t) => t[key] === obj[key])
    );
  }
  const onFinish = async (values) => {
    const data = values;
    const existingItems = removeDuplicates(currentItems, 'id');
    const validObjects = [];
    const emptyObjects = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const obj = data[key];

        if ((obj.quantity_authorised !== null && obj.quantity_authorised !== 0) || (obj.quantity_on_farm !== null && obj.quantity_on_farm !== 0)) {
          validObjects.push({ id: key, ...obj });
        } else {
          emptyObjects.push({ id: key, ...obj });
        }
      }
    }

    console.log("Objects with valid values:", validObjects);
    console.log("Objects with empty values:", emptyObjects);
    let validItemIds = [];

    for (let i = 0; i < validObjects.length; i++) {
      const existingItem = existingItems.find(item => item.item_id === validObjects[i].id);

      if (existingItem) {
        // Update existing item
        const { data, error } = await supabase
          .from('rvm_medication_data')
          .update({
            quantity_authorised: validObjects[i].quantity_authorised,
            quantity_on_farm: validObjects[i].quantity_on_farm
          })
          .eq('item_id', validObjects[i].id)
          .eq('rvm_id', rvmId)
          .select();

        if (error) {
          console.log(error);
        } else {
          console.log('Update successful');
          // message.success('RVM Medication updated');
          validItemIds.push(validObjects[i].id);
        }
      } else {
        // Insert new item
        const { data, error } = await supabase
          .from('rvm_medication_data')
          .insert({
            item_id: validObjects[i].id,
            rvm_id: rvmId,
            quantity_authorised: validObjects[i].quantity_authorised,
            quantity_on_farm: validObjects[i].quantity_on_farm
          })
          .select();

        if (error) {
          console.log(error);
        } else {
          console.log('Insert successful');
          // message.success('RVM Medication updated');
          validItemIds.push(validObjects[i].id);
        }
      }
    }

    for (let i = 0; i < emptyObjects.length; i++) {
      const existingItem = existingItems.find(item => item.item_id === emptyObjects[i].id);

      if (existingItem) {
        // Delete item from Supabase
        const { data, error } = await supabase
          .from('rvm_medication_data')
          .delete()
          .eq('item_id', emptyObjects[i].id)
          .eq('rvm_id', rvmId);

        if (error) {
          console.error('Error deleting item:', error);
        } else {
          console.log('Deleted item from db');
          message.success('Item with 0 values deleted successfully');
        }
      }
    }

    console.log(validItemIds);
    console.log(existingItems);

    // Fetch the updated medication data
    const { data: updatedMedicationData, error: updatedMedicationError } = await supabase
      .from('rvm_medication_data')
      .select()
      .eq('rvm_id', rvmId);

    if (updatedMedicationError) {
      console.error('Error fetching updated medication data:', updatedMedicationError);
      return;
    }

    // Update the state with the updated medication data
    setCurrentItems(updatedMedicationData);
  };

  const debouncedSave = useCallback(
    debounce(async (values) => {
      setSaving(true);
      try {
        await onFinish(values);
        message.success({
          content: 'Auto-saved successfully',
          style: {
            marginTop: '100px',
            color: '#52c41a',
          },
        });
      } catch (error) {
        message.error({
          content: 'Failed to auto-save',
          style: {
            marginTop: '100px',
            color: '#ff4d4f',
          },
        });
      } finally {
        setSaving(false);
      }
    }, 1000),
    [rvmId]
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredRvmItems = rvmItems.filter(item => item.item.toLowerCase().includes(searchQuery.toLowerCase()));

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  // Handle form value changes
  const handleFormValueChange = async (changedValues, allValues) => {
    debouncedSave(allValues);
  };

  const preventScroll = (e) => {
    console.log('Preventing scroll');
    e.preventDefault();
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item_id',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      filters: [
        {
          text: 'Anti-inflammatory',
          value: 'Anti',
        },
        {
          text: 'Calf Scour',
          value: 'Calf',
        },
        {
          text: 'Dry Cow Therapy',
          value: 'Dry',
        },
        {
          text: 'Injectable Abx',
          value: 'Inj',
        },
        {
          text: 'Mastitis - Intramammary',
          value: 'Mast',
        },
        {
          text: 'Minerals',
          value: 'Mine',
        },
        {
          text: 'Miscellaneous',
          value: 'Misc',
        },
        {
          text: 'Topical',
          value: 'Top',
        },
        {
          text: 'Vaccines',
          value: 'Vacc',
        },
        {
          text: 'Vet or Vet Technician to Administer Only',
          value: 'Vet',
        },
        {
          text: 'Vitamins',
          value: 'Vita',
        },
      ],
      onFilter: (value, record) => record.group.startsWith(value),
      filterSearch: true,
    },
    {
      title: 'Quantity Authorised',
      dataIndex: 'quantity_authorised',
      key: 'quantityAuthorised',
      render: (text, record) => (
        <Form.Item
          name={[record.id, 'quantity_authorised']}
          initialValue={record.quantity_authorised}
        >
          <InputNumber
            min={0}
            keyboard={false}
            onWheel={(e) => {
              // Prevent scroll only when the input is focused
              if (document.activeElement === e.target) {
                e.preventDefault();
              }
            }}
            onBlur={() => {
              const values = form.getFieldsValue();
              debouncedSave(values);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Quantity on Farm',
      dataIndex: 'quantity_on_farm',
      key: 'quantity_on_farm',
      render: (text, record) => (
        <Form.Item
          name={[record.id, 'quantity_on_farm']}
          initialValue={record.quantity_on_farm}
        >
          <InputNumber
            min={0}
            keyboard={false}
            onWheel={(e) => {
              // Prevent scroll only when the input is focused
              if (document.activeElement === e.target) {
                e.preventDefault();
              }
            }}
            onBlur={() => {
              const values = form.getFieldsValue();
              debouncedSave(values);
            }}
          />
        </Form.Item>
      ),
    },
  ];


  const handleExcelButtonClick = async () => {
    setLoading(true);
    try {
      // Call the onFinish function to save the data
      await form.validateFields();
      const values = await form.getFieldsValue();
      await onFinish(values);

      // Proceed with the export logic
      const relatedTables = ['rvm_farm_objectives_data', 'rvm_medication_data'];

      const rvmData = await fetchData('rvms', 'id', rvmId);
      const farmData = rvmData?.farm_id ? await fetchData('farms', 'id', rvmData.farm_id) : null;
      const clinicData = farmData?.clinic_id ? await fetchData('clinics', 'id', farmData.clinic_id) : null;

      let farmVetData = null;
      if (farmData.vet) {
        farmVetData = await fetchData('users', 'id', farmData.vet);
      }

      // Fetch rvm_medication_data and rvm_items
      const { data: medicationData, error: medicationError } = await supabase
        .from('rvm_medication_data')
        .select('*')
        .eq('rvm_id', rvmId);

      if (medicationError) {
        console.error('Error fetching medication data:', medicationError);
        throw new Error('Error fetching medication data');
      }

      const { data: itemsData, error: itemsError } = await supabase.from('rvm_items').select('*');

      if (itemsError) {
        console.error('Error fetching items data:', itemsError);
        throw new Error('Error fetching items data');
      }

      // Merge medication data with item data
      const medicationDataWithItems = medicationData.map((medication) => {
        const item = itemsData.find((item) => item.id === medication.item_id);
        return {
          ...medication,
          ...item,
        };
      });

      const relatedData = await Promise.all(
        relatedTables.map((tableName) => fetchRelatedData(tableName, rvmId))
      );

      const combinedData = {
        rvmData,
        farmData,
        clinicData,
        ...(farmVetData && { farmVetData }),
        relatedData: relatedData.reduce((accumulator, currentValue, index) => {
          if (relatedTables[index] === 'rvm_medication_data') {
            accumulator[relatedTables[index]] = medicationDataWithItems;
          } else {
            accumulator[relatedTables[index]] = currentValue;
          }
          return accumulator;
        }, {}),
      };

      //    console.log('Combined data:', combinedData);
      //    message.success({
      //     content: 'Data combined successfully',
      //     style: {
      //      marginTop: '100px',
      //      color: '#52c41a',
      //     },
      //    });

      const excelData = excelValues(combinedData);
      console.log('Excel data:', excelData);

      const response = await fetch(cloudflareWorkerUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(excelData),
      });

      if (!response.ok) {
        console.error('Network response was not ok');
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${combinedData.farmData.farm_name}-RVM-Authorisation-${combinedData.rvmData.start_date}-${combinedData.rvmData.end_date}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      setLoading(false);
      message.success({
        content: 'RVM report downloaded successfully',
        style: {
          marginTop: '100px',
          color: '#52c41a',
        },
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      message.error({
        content: 'Error downloading RVM report',
        style: {
          marginTop: '100px',
          color: '#ff4d4f',
        },
      });
    }
  };

  useEffect(() => {
    const tableBody = document.querySelector('.ant-table-body');
    if (tableBody) {
      const handleWheel = (e) => {
        const target = e.target;
        // Only prevent scroll if the target is an input number or its parent
        if (
          target.classList.contains('ant-input-number-input') ||
          target.classList.contains('ant-input-number')
        ) {
          e.stopPropagation();
        }
      };

      tableBody.addEventListener('wheel', handleWheel, { passive: false });

      return () => {
        tableBody.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: 'calc(12px + var(--Header-height))',
          sm: 'calc(12px + var(--Header-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Spin spinning={loading || saving}>
        <Box
          sx={{
            paddingBottom: '2em',
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
          <Input
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search items"
          />
          {saving && <span style={{ color: '#1890ff' }}>Saving...</span>}
        </Box>
        <Form
          form={form}
          onFinish={onFinish}
          onValuesChange={handleFormValueChange}
        >
          <Table
            dataSource={filteredRvmItems}
            columns={columns}
            rowKey="id"
            pagination={false}
            scroll={{ y: '60vh' }}
          />
          <Divider />
          <Button type="primary" htmlType="submit">Save</Button>
          <Divider type='vertical' />
          <Button
            type="default"
            onClick={handleExcelButtonClick}
            disabled={loading || saving}
          >
            RVM authorisation documents
          </Button>
        </Form>
      </Spin>
    </Box>
  );
};

export default RvmItemsTable;