import React from 'react';
import { Form, Input, InputNumber } from 'antd';

const HealthCostsForm = ({ form, onFinish, editingId }) => {
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter a name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="price"
        label="Price"
        rules={[{ required: true, message: 'Please enter a price' }]}
      >
        <InputNumber
          min={0}
          step={0.01}
          style={{ width: '100%' }}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
    </Form>
  );
};

export default HealthCostsForm;