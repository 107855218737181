import React, { useEffect, useState } from 'react';
import { Button, Divider, Spin, message } from 'antd';
import { useParams } from 'react-router-dom';
import supabase from '~supabaseConfig';
import { useMsal, useAccount } from '@azure/msal-react';


// Customize the message component
message.config({
  top: 100,
  duration: 5,
  maxCount: 3,
  rtl: true,
});

const ConsultBottomNav = ({
  consultType,
  consultIdParam,
  supabaseTable,
  cloudflareWorkerUrl,
  excelGenerator,
  reportName,
  fetchDataFunction,
  additionalFetchFunctions = [],
}) => {
  const params = useParams();
  const consultId = params[consultIdParam];
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(false);
  const { accounts } = useMsal();
  const user = useAccount(accounts[0]);

  useEffect(() => {
    setUsername(user.name);
  }, [user]);

  const handleExcelButtonClick = async () => {
    setLoading(true);
    try {
      const consultData = await fetchDataFunction(supabaseTable, 'id', consultId);

      let additionalData = {};
      for (const fetchFunc of additionalFetchFunctions) {
        const data = await fetchFunc(consultId, consultData);
        additionalData = { ...additionalData, ...data };
      }

      const combinedData = {
        username,
        consultData,
        ...additionalData,
      };

      console.log('Combined data:', combinedData);

      // Add await here
      const excelData = await excelGenerator(combinedData);
      console.log('Excel data:', excelData);

      const response = await fetch(cloudflareWorkerUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(excelData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      let reportFileName;

      const getFarmName = (data) => {
        if (data.consultData.farms) {
          return data.consultData.farms.farm_name;
        } else if (data.farmData) {
          return data.farmData.farm_name;
        }
        throw new Error('Farm name not found in data structure');
      };

      switch (reportName) {
        case 'RVM':
          reportFileName = `${getFarmName(combinedData)}-${reportName}-${combinedData.consultData.start_date}-${combinedData.consultData.end_date}.xlsm`;
          break;
        case 'RVM-Auth':
          reportFileName = `${getFarmName(combinedData)}-${reportName}-${combinedData.consultData.start_date}-${combinedData.consultData.end_date}.xlsx`;
          break;
        case 'Wellbeing':
          reportFileName = `${getFarmName(combinedData)}-Wellbeing-${combinedData.consultData.start_date}-${combinedData.consultData.end_date}.xlsx`;
          break;
        case 'Milk Quality Consult':
          reportFileName = `${getFarmName(combinedData)}-Milk-Quality-Report-${combinedData.consultData.start_date}-${combinedData.consultData.end_date}.xlsx`;
          break;
        default:
          throw new Error("File name is incorrect");
      }

      console.log(reportFileName);

      a.download = reportFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      setLoading(false);
      message.success({
        content: `${reportName} Report downloaded successfully`,
        style: {
          marginTop: '100px',
          color: '#52c41a',
        },
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      message.error({
        content: `Error downloading ${reportName} Report: ${error.message}`,
        style: {
          marginTop: '100px',
          color: '#ff4d4f',
        },
      });
    }
  };

  return (
    <div>
      <Divider />
      <Button onClick={handleExcelButtonClick} disabled={loading}>
        Download {reportName} report
      </Button>
      {loading && <Spin style={{ marginLeft: '8px' }} />}
    </div>
  );
};

export default ConsultBottomNav;