import React from 'react';
import { Form, Input, InputNumber, Select } from 'antd';

const ProductForm = ({ form, onFinish, editingId }) => {
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        price: 0,
        category: 'General'
      }}
    >
      <Form.Item
        name="code"
        label="Product Code"
        rules={[{ required: true, message: 'Please enter a product code' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="product_name"
        label="Product Name"
        rules={[{ required: true, message: 'Please enter a product name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="category"
        label="Category"
        rules={[{ required: true, message: 'Please select a category' }]}
      >
        <Select>
          <Select.Option value="General">General</Select.Option>
          <Select.Option value="Medicine">Medicine</Select.Option>
          <Select.Option value="Equipment">Equipment</Select.Option>
          <Select.Option value="Supplies">Supplies</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
  name="price"
  label="Price"
  rules={[{ required: true, message: 'Please enter a price' }]}
>
  <InputNumber
    min={0}
    style={{ width: '100%' }}
    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // Adds $ and commas
    parser={(value) => value.replace(/\$\s?|(,*)/g, '')} // Parses the number correctly
  />
</Form.Item>




    </Form>
  );
};

export default ProductForm;