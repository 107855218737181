import React from 'react';
import { Table, Space } from 'antd';

const ProductTable = ({ items, onEdit, onDelete }) => {
  const columns = [
    { 
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      width: '15%',
    },
    { 
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      width: '30%',
    },
    { 
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: [
        { text: 'General', value: 'General' },
        { text: 'Medicine', value: 'Medicine' },
        { text: 'Equipment', value: 'Equipment' },
        { text: 'Supplies', value: 'Supplies' },
      ],
      onFilter: (value, record) => record.category === value,
      width: '20%',
    },
    { 
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${Number(price).toFixed(2)}`,
      sorter: (a, b) => a.price - b.price,
      width: '15%',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <Space size="middle">
          <button
            onClick={() => onEdit(record)}
            className="text-blue-600 hover:text-blue-800"
          >
            Edit
          </button>
          <button
            onClick={() => onDelete(record.id)}
            className="text-red-600 hover:text-red-800"
          >
            Delete
          </button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowKey="id"
      pagination={{ 
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`
      }}
    />
  );
};

export default ProductTable;